import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "CSS Grid Beats Flexbox for Full Page Layout",
  "date": "2017-09-11",
  "promo": "grids",
  "description": "Reasons to choose Grid instead of Flexbox for the full layout of your app/site.",
  "pic": "/img/grid-beats-flexbox.jpg",
  "color": "#00d6e6"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Ahh that sweet moment when you and your new app have your first style together:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.app {
}
`}</code></pre>
    <p>{`But now what? Should you build your app's main container layout using Grid or Flexbox?`}</p>
    <p>{`Honestly you could pull it off with either. But the new `}<a parentName="p" {...{
        "href": "https://gridcritters.com/"
      }}>{`CSS Grid layout`}</a>{` has some big advantages over `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`Flexbox`}</a>{` when it comes to building a full page layout. Let's take a look.`}</p>
    <h2>{`Control In Both Directions`}</h2>
    <p>{`With Flexbox you have to choose which way your flex container flows: up, down, left or right. Your flex items are placed into the flex container until there's no more room for them all, and then based on their `}<a parentName="p" {...{
        "href": "/post/the-difference-between-width-and-flex-basis/"
      }}>{`flex-basis`}</a>{` will either shrink, overflow, or `}<a parentName="p" {...{
        "href": "/post/flex-wrap/"
      }}>{`wrap`}</a>{`. You can `}<em parentName="p">{`mimic`}</em>{` a two-dimensional layout using `}<inlineCode parentName="p">{`flex-wrap`}</inlineCode>{`, but you don't have nearly as much control over the lines as Grid gives you. You also run into problems with getting the correct spacing for items on the last line since each flex line behaves as its own separate flex container for spacing and alignment.`}</p>
    <p>{`Grid layout gives you a lot more power and control. Here's an example that creates a Grid of two equal sized columns, a `}<inlineCode parentName="p">{`100px`}</inlineCode>{` header and footer, and flexible space in the middle. With just a couple line of CSS we're able to tell the blue item to take up two columns, and the purple item to take up two rows:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      height: 400px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 100px 1fr 100px;
    }\n
    .purple {
      grid-row: span 2;
    }\n
    .blue {
      grid-column: span 2;
    }
  `} html={`
    <div class="container">
      <div class="item grey"></div>
      <div class="item purple"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`Once you get the hang of Grids, two dimensional layouts like this are a snap.`}</p>
    <h2>{`Flexbox Takes More Rules, Divs, Work`}</h2>
    <p>{`The example above could have been built with Flexbox, but it would require a lot more to make it happen.`}</p>
    <p>{`What the heck, let's build the example again in Flexbox just to see the difference:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      outline: 5px solid black;
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 400px;
    }\n
    .top{
      display: flex;
      flex-direction: row;
      flex-grow: 1;
    }\n
    .bottom {
      display: flex;
      flex-direction: column;
    }\n
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
    }\n
    .right {
      width: 50%;
      display: flex;
    }\n
    .grey {
      flex-basis: 100px;
    }\n
    .blue {
      flex-basis: 100px;
    }\n
    .orange, .purple, .blue {
      flex-grow: 1;
    }
  `} html={`
    <div class="container">
      <div class="top">
        <div class="left">
          <div class="item grey"></div>
          <div class="item orange"></div>
        </div>
        <div class="right">
          <div class="item purple"></div>   
        </div>    
      </div>
      <div class="bottom">
        <div class="item blue"></div>
      </div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`We got it, but not without adding four extra divs, 5 extra style rules, and having to use 5 flex containers (with varying directions) as opposed to just 1 grid container.`}</p>
    <h2>{`Start With a Grid`}</h2>
    <p>{`CSS Grid layout was designed for this kind of thing, and it shows. These days it makes a lot of sense to start new apps & full page layouts with a Grid. Here are a couple `}<em parentName="p">{`Happy Little Divs`}</em>{` videos that show how well Grid works for the `}<a parentName="p" {...{
        "href": "/build/zelda/"
      }}>{`Zelda UI`}</a>{` and the `}<a parentName="p" {...{
        "href": "/build/overwatch-hero-picker/"
      }}>{`Overwatch UI`}</a>{`.`}</p>
    <p>{`If you want to become a master of Grid layout check out my `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` Mastery Game.`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`Start building that side project idea you've been meaning to do forever, using the brand new CSS Grid layout.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      